.f-MainContent {
  padding: 3vh 0px;
}

#f-MainContent1 {
  padding: 0 10%;
}

#f-MainContent1 .not-centered-title {
  margin-top: 10px;
}

#f-MainContent1 div {
  text-align: center;
  width: 60%;
}

#f-MainContent1 span {
  margin: 10vh 0 0 0;
  width: 80%;
}

#f-MainContent1 img {
  display: inline;
  height: 45vh;
}

#f-MainContent {
  padding: 10vh 10%;
}

#f-MainContent span {
  margin: 10vh 0 0 0;
}

#f-MainContent #chart-img {
  display: inline;
  height: 20vh;
  width: 100%;
}

#f-MainContent #chart-title {
  margin: 3vh 10%;
}

.ad-content1 {
  width: 100%;
  margin-right: 10%;
}
.ad-content2 {
  width: 100%;
}

.f-MainContent3 div {
  text-align: center;
}

.f-MainContent3 {
  padding-bottom: 10vh;
  display: "inline";
}

.f-MainContent3 img {
  height: 100px;
  display: "inline";
  padding: 5vh 0;
}

.f-MainContent3 .subtitle {
  padding: 0 30%;
  display: "inline";
}

@media (max-width: 600px) {
  .f-MainContent {
    padding: 3vh 3%;
  }
  .intContent1 {
    display: inline;
  }

  .ad-content1 {
    margin-right: 0;
    margin-left: -20px;
  }
  #f-MainContent1 .not-centered-title {
    padding: 5vh 1%;
  }

  #f-MainContent1 img {
    height: 50vh;
    margin-left: 30%;
  }

  #f-MainContent {
    padding: 3vh 10%;
  }
  #f-MainContent .feature-img2 {
    width: 100%;
  }

  #f-MainContent span {
    text-align: center;
  }
  #f-MainContent .ad-content2 {
    margin-top: 15px;
  }

  .f-MainContent3 .subtitle {
    padding: 0 3%;
  }
}
