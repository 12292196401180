.terms-root {
  padding: 35px;
}
.terms-root ul {
  padding: 15px 30px;
}

.terms-root .not-centered-smallTitle {
  max-width: 100%;
}
