.cardRoot {
  background-color: #fff;
  border-radius: 6px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px;
}
.cardRoot2 {
  background-color: #f5f5f5;
  border-radius: 6px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px;
  cursor: pointer;
}

.cardTitle {
  font-size: 24px;
  font-weight: 500;
  padding: 10px;
}

.cardSubtitle {
  font-size: 16px;
  padding: 10px;
}
.cart-elt-2 {
  padding: 5vh 10px;
  margin-left: -10%;
}

#cardImg {
  border-radius: 6px 0 0 6px;
  width: 80%;
}

.b-cardRoot {
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  width: 100%;
  height: 25vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.b-cardTitle {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0;
}

.b-cardSubtitle {
  font-size: 16px;
  padding: 10px 0;
}

#cardImg-2 {
  border-radius: 6px 0 0 6px;
  width: 50%;
}

.cart-elt-2-colored {
  padding: 3vh 10px;
  margin-left: -50%;
  width: 100%;
  height: 10vh;
  cursor: pointer;
}

@media (max-width: 600px) {
  .cardRoot {
    grid-template-columns: repeat(1, 1fr);
  }

  #cardImg {
    border-radius: 6px 6px 0 0;
  }
  .cart-elt-2 {
    padding: 3vh 30px;
    margin: 0;
  }

  #cardImg {
    border-radius: 6px 0 0 6px;
    width: 100%;
  }
  .b-cardRoot {
    height: 30vh;
  }
  .b-cardRoot .row-space-between {
    flex-direction: row;
  }

  .cart-elt-2-colored {
    padding: 3vh 30px;
    margin: 0;
    width: inherit;
    height: inherit;
  }

  #cardImg-2 {
    width: 100%;

    border-radius: 6px 6px 0 0;
  }
}
