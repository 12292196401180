.inputField {
  background-color: #e7eaed;
  padding: 7px 25px;
  border: 1px solid #fff;
  border-radius: 3px;
  margin: 10px;
  outline: none;
}

.inputTextarea {
  background-color: #e7eaed;
  padding: 7px 25px;
  border: 1px solid #fff;
  border-radius: 3px;
  margin: 10px;
  outline: none;
}

.label {
  padding: 10px;
}
