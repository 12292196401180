.advertisersMainContent {
  padding: 3vh 0px;
}

#advertisersMainContent1 .not-centered-title {
  margin-top: 10px;
  padding: 5vh 15%;
}

#advertisersMainContent1 div {
  text-align: center;
  width: 100%;
}
#advertisersMainContent1 img {
  display: inline;
  height: 30vh;
}

#advertisersMainContent2 {
  padding: 10vh 15%;
}
.ad-content1 {
  width: 100%;
  margin-right: 10%;
}
.ad-content2 {
  width: 100%;
}

.advertisersMainContent3 div {
  text-align: center;
}

.advertisersMainContent3 {
  padding-bottom: 10vh;
  display: "inline";
}

.advertisersMainContent3 img {
  height: 100px;
  display: "inline";
  padding: 5vh 0;
}

.advertisersMainContent3 .subtitle {
  padding: 0 30%;
  display: "inline";
}
@media (max-width: 1000px) {
  #advertisersMainContent1 img {
    height: 20vh;
    margin-left: -15%;
  }
}

@media (max-width: 600px) {
  .advertisersMainContent {
    padding: 3vh 3%;
  }
  .intContent1 {
    display: inline;
  }

  .ad-content1 {
    margin-right: 0;
    margin-left: -20px;
  }
  #advertisersMainContent1 .not-centered-title {
    padding: 5vh 1%;
  }

  #advertisersMainContent1 img {
    height: 25vh;
    margin-left: 0;
  }
  #advertisersMainContent2 {
    padding: 3vh 10%;
  }
  #advertisersMainContent2 .ad-content2 {
    margin-top: 15px;
  }

  .advertisersMainContent3 .subtitle {
    padding: 0 3%;
  }
}
