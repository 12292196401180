#introduction {
  padding: 5vh 5%;
  margin: 0 -5px;
}

#introduction img {
  height: 50vh;
  width: 100%;
  border-radius: 3px;
}

#introduction .title {
  font-size: 36px;
  text-align: unset;
  max-width: 500px;
  margin-top: 10vh;
}

.intro-button {
  background-color: #1876d1;
  color: white;
  border: 1px solid #1876d1;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 6px;
  cursor: pointer;
}

.intro-button:hover {
  background-color: #fff;
  color: #1876d1;
  border: 1px solid #1876d1;
}

.imgIntroductionRoot {
  color: #fff;
  padding: 10px 0;
}

.imgIntroduction {
  margin-top: 5vh;
  position: absolute;
  z-index: 2;
  padding: 0 10%;
}

.imgIntroductionTitle {
  font-size: 52px;
  width: 50%;
  padding: 5vh 0;
}

@media (max-width: 600px) {
  #introduction {
    padding: 5vh 0;
    margin: 0px;
    width: 100%;
    text-align: center;
  }
  #introduction img {
    height: 40vh;
    display: inline;
    width: 90%;
  }

  #introduction .title {
    margin-top: 10px;
  }

  .imgIntroductionTitle {
    font-size: 32px;
    width: unset;
  }

  .imgIntroduction .button {
    display: inline;
  }
}
