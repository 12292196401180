.mainContent {
  padding: 3vh 10px;
}

.mainContent #mainContent2 {
  padding: 5vh 10%;
}

.mainContent #mainContent2 img {
  width: 120%;
}

.mainContent1 .subtitle {
  padding: 10px 10%;
}

.mainContent3 {
  padding: 10px;
}

.mainContent3 .img {
  height: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.testimoniesRoot {
  background-color: #f5f5f5;
  padding: 10px;
}

.convincing {
  background-color: #1876d1;
  color: #fff;
  border-radius: 6px;
  margin: 40px 20px;
  padding: 10px;
}

.convincing .btnHolder {
  text-align: center;
  padding-bottom: 20px;
}

.convincing .button {
  background-color: #fff;
  color: #1876d1;
  border: 1px solid #fff;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  display: inline;
}

.convincing .button:hover {
  background-color: #1876d1;
  color: white;
  border: 1px solid #fff;
}
.convincing .title {
  text-align: none;
  font-size: 28px;
  font-weight: 500;
}

@media (max-width: 600px) {
  .imgTitle {
    font-size: 32px;
    width: unset;
  }
  .imgContent .button {
    display: inline;
  }
  .mainContent #mainContent2 {
    padding: 5vh 0;
  }
  .mainContent #mainContent2 img {
    width: 110%;
    margin-left: -25px;
  }
}
